/*
	Identity by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body:after {
		display: none;
	}

/* Main */

	#main {
		-moz-transform: none !important;
		-webkit-transform: none !important;
		-ms-transform: none !important;
		transform: none !important;
		opacity: 1 !important;
	}